<div class="terms-container">
  <header class="flex space-between">
    <h3>TERMS & CONDITIONS</h3>
    <em (click)="closePopUp()" class="bx bx-x"></em>
  </header>
  <section>
    <em>Last updated June 18th, 2024</em>
    <br />
    <h3>1. SERVICES AND SUPPORT</h3>
    <br />
    <p>
      CETV Now, LLC (“CETV”) is the developer and exclusive owner of a content control and targeted advertising and
      monetization product, branded and marketed under the name, CETV™ (the “Product”). CETV makes the Product available
      to (a) Unit (as that term is defined below) partners (each, a “Host Partner”), who currently have or will have
      video programming at one or more commercial establishment (each, a “Location”) provided by its programming and
      content provider (currently, DirecTV, LLC (“DTV”)), and (b) certain advertisers (each, an “Advertiser”) who seek
      to have their advertising (the “Ads”) displayed through CETV’s proprietary advertising network (the “Ad Network”),
      on one or more CETV branded television screens (each, a “Unit”), that will be placed in the Locations of the Host
      Partners. The Ads will be sold by one or more of CETV’s advertising sales partners (each, an “Ad Agency”). CETV
      will use commercially reasonable efforts to provide Host Partner with the ability to access and use the Product
      for its Unit(s) and will provide support to the Host Partner in connection therewith (collectively, the
      “Services”) pursuant to these Host Partner Services Agreement Terms Service (“TOS”). This TOS pertains to Host
      Partners only. A separate terms of service for all Advertiser’s can be found at
      <a href="https://advertiser.cetvnow.com/login" target="_blank">https://advertiser.cetvnow.com/login</a>
    </p>
    <br />
    <p>
      1.1 The Product enables Host Partner to regulate the channels, schedules and settings of its television
      programming on the Unit(s) placed at one or more of Host Partner’s Locations. CETV will provide the Unit(s) to
      Host Partner as determined in make, model, style, and number by CETV in its sole discretion. The Unit(s) will
      contain CETV’s proprietary application (the “App”), which will connect to CETV’s cloud services and will be
      accessible to Host Partner through CETV’s website at
      <a href="https://www.cetvnow.com/" target="_blank">cetvnow.com</a>
      (the “Website”).
    </p>
    <br />
    <p>Through the Website, Host Partner will be able to:</p>
    <br />
    <p>• Display on the Unit(s) only the content that most aligns with Host Partner’s brand experience; and</p>
    <br />
    <p>
      • Control the Unit(s)’s television channels at all locations in real time or build programming schedules to run
      programming on the Unit(s)’s television channels at all locations hands free.
    </p>
    <br />
    <p>
      CETV will provide Host Partner with a DTV package for the Unit(s) at CETV’s cost at a level CETV will in its sole
      and exclusive discretion. The Unit(s) must be conspicuously placed in the location(s) in areas to maximize
      viewership by Host Partner’s patrons as to be reasonably agreed between CETV and Host Partner. Host Partner must
      maintain the DTV package in order to use the Product on the Unit(s). The Product is not available outside the
      Unit(s). The Unit(s) will remain the property of CETV until Host Partner pays in full the Unit(s) Fee (as defined
      in Section 1.2 below) for the Unit(s) at which time such Unit(s) shall become the property of Host Partner. Host
      Partner must run uninterrupted programming on the Unit(s) through the App during its standard business operating
      hours. Host Partner understands that Ads are shown through the Unit(s) and agrees to run such Ads. Host Partner
      must provide and maintain internet connectivity with minimum broadband connection speeds of 25 Mbps download and 3
      Mbps upload, which minimum speeds are subject to change in CETV’s sole discretion. Host Partner must provide and
      maintain internet connectivity with minimum broadband connection speeds of 25 Mbps download and 3 Mbps upload,
      which minimum speeds are subject to change in CETV’s sole discretion.
    </p>
    <br />
    <p>
      1.2 The Product will connect Host Partner’s Unit(s) through the App to third-party advertisers to run their Ads on
      the Unit(s). CETV will pay to Host Partner payments (the “Revenue Share Payments”) equaling fifty percent (50%) of
      the net revenues (less Transaction Fees, as defined below) it collects for third-party Ads generated through the
      Ad Network that Host Partner displays to its patrons through the Unit(s), minus (A) a service fee of $49.00 per
      month (the “Service Fee”) and (B) $100.00 per month for the first twelve (12) months of the Term (as defined
      below), (until the cost of each such Unit has been recouped by CETV) (in each case, the “Unit(s) Fee”).
      “Transaction Fees” are CETV’s costs and expenses for processing its transactions with Host Partner including, but
      not limited to, merchant processing fees and third-party dealer continuing service fees and commissions. Host
      Partner will have access through the Website to track the amount of revenue generated under the “Revenue” tab in
      Host Partner’s CETV account. CETV will pay Host Partner the Revenue Share Payments by issuing Automatic Clearing
      House (“ACH”) payments upon the completion of any campaign in which that displayed Ads on the Unit(s) once the
      Service Fee and Unit(s) Fee threshold amounts are reached each month.
    </p>
    <br />
    <p>
      Each Host Partner is required to set up and maintain an account with Stripe during the Term in order to receive
      its Revenue Share Payments (such account, the “Stripe Account”). Host Partners without a Stripe Account will be
      subject to a ten percent (10%) monthly service fee on all Revenue Share Payments (the “Monthly Service Fee”)
      beginning with the second (2nd) month after the Start Date (as defined below) and continuing each month thereafter
      until such Stripe Account is maintained by the Host Partner. Host Partner is not entitled to any reimbursement of
      the Monthly Service Fee upon establishing its Stripe Account.
    </p>
    <br />
    <p>
      CETV will have and maintain the sole and exclusive discretion to determine and adjust its pricing for the Ads, to
      offer credits, and to provide no-cost Ads. CETV will not provide any refunds or credits relating to any of the
      Product and/or the Services under any circumstances, and Host Partner expressly waives any rights therewith.
    </p>
    <br />

    <h3>2. RESTRICTIONS AND RESPONSIBILITIES</h3>
    <br />
    <p>
      2.1 Host Partner will not, directly or indirectly: reverse engineer, decompile, disassemble or otherwise attempt
      to discover the source code, object code or underlying structure, ideas, know-how or algorithms relevant to the
      Product and/or the Services or any software, documentation or data related to the Product and/or the Services
      (“Software”); modify, translate, or create derivative works based on the Product and/or the Services or any
      Software (except to the extent expressly permitted by CETV or authorized within the Product and/or the Services);
      use the Product and/or the Services or any Software for timesharing or service bureau purposes or otherwise for
      the benefit of a third; or remove any proprietary notices or labels. With respect to any Software that is
      distributed or provided to Host Partner for use on dedicated devices, including the Unit(s), CETV hereby grants
      Host Partner a non-exclusive, non-transferable, non-sublicensable license to use such Software during the Term
      only in connection with the Product and/or the Services.
    </p>
    <br />
    <p>
      2.2 Host Partner represents, covenants, and warrants that Host Partner will use the Product and/or the Services
      only in compliance with these TOS and any other CETV standard published policies then in effect and all applicable
      laws, ordinances, and regulations. Host Partner hereby agrees to indemnify and hold harmless CETV against any
      damages, losses, liabilities, settlements and expenses (including without limitation costs and attorneys’ fees) in
      connection with any liability, debt, penalty, claim or action that arises from an alleged violation of the
      foregoing or otherwise from Host Partner’s use of Product and/or the Services. CETV may monitor Host Partner’s use
      of the Product and/or the Services, and may prohibit any use of the Product and/or Services it believes may be (or
      alleged to be) in violation of the foregoing but shall not be obligated to do so.
    </p>
    <br />
    <p>
      2.3 Host Partner shall be responsible for obtaining and maintaining any equipment and ancillary services needed to
      connect to, access or otherwise use the Services which, including, without limitation, modems, hardware, servers,
      software, operating systems, networking, web servers and the like (collectively, “Equipment”). Host Partner shall
      also be responsible for maintaining the security of the Equipment, Host Partner account, passwords (including but
      not limited to administrative and user passwords) and files, and for all uses of Host Partner account or the
      Equipment with or without Host’s knowledge or consent.
    </p>
    <br />

    <h3>3. CONFIDENTIALITY; PROPRIETARY RIGHTS</h3>
    <br />
    <p>
      3.1 Host Partner understands that CETV has disclosed or may disclose business, technical or financial information
      relating to CETV’s business (hereinafter referred to as “Proprietary Information” of CETV). Proprietary
      Information of CETV includes, but is not limited to non-public information regarding features, functionality and
      performance of the Product and/or the Services. The Host Partner agrees: (i) to take reasonable precautions to
      protect such Proprietary Information, and (ii) not to use (except in performance of the Product and/or the
      Services or as otherwise permitted herein) or divulge to any third person any such Proprietary Information. CETV
      agrees that the foregoing shall not apply with respect to any information after five (5) years following the
      disclosure thereof or any information that the Host Partner can document (a) is or becomes generally available to
      the public through no fault of Host Partner, or (b) was in its possession or known by Host Partner prior to
      receipt from CETV, or (c) was rightfully disclosed to Host Partner without restriction by a third party, or (d)
      was independently developed without use of any Proprietary Information of CETV or (e) is required to be disclosed
      by law.
    </p>
    <br />
    <p>
      3.2 CETV shall own and retain all right, title and interest in and to (a) the Product and/or the Services and
      Software, all improvements, enhancements or modifications thereto, (b) any software, applications, inventions or
      other technology developed in connection with implementation of the Product and/or the Services or support, and
      (c) all intellectual property rights related to any of the foregoing.
    </p>
    <br />
    <p>
      3.3 Notwithstanding anything to the contrary, CETV shall have the right collect and analyze data and other
      information relating to the provision, use and performance of various aspects of the Product and/or the Services
      and related systems and technologies (“Host Partner Data”), including, without limitation, information concerning
      Host Partner Data and data derived therefrom, and CETV will be free (during and after the term hereof) to (i) use
      such information and data to improve and enhance the Product and/or the Services and for other development,
      diagnostic and corrective purposes in connection with the Product and/or the Services and other CETV offerings,
      and (ii) disclose such data solely in aggregate or other de-identified form in connection with its business. No
      rights or licenses are granted except as expressly set forth herein.
    </p>
    <br />

    <h3>4. ACCOUNT SETUP</h3>
    <br />
    <p>
      4.1 To set up a CETV account, Host Partner must first sign up through the Website and add its business to the
      Unit(s).
    </p>
    <br />
    <p>
      4.2 Host Partner’s Subscription term begins (the “Start Date”) on the day of the month that the Unit(s) is
      activated.
    </p>
    <br />

    <h3>5. TERM AND TERMINATION</h3>
    <br />
    <p>
      5.1 Subject to earlier termination as provided in Section 5.3, this Agreement is for the Initial Service Term as
      specified above, and shall be automatically renewed for additional one (1) year periods (collectively, the
      “Term”), unless either party requests termination pursuant to Section 5.2 below.
    </p>
    <br />
    <p>
      5.2 Either Party may cancel this Agreement by written notice tendered to the other Party pursuant to Section 10.1
      below at least ninety (90) days prior to the end of the Term indicating the Terminating Party’s intent to
      terminate the Product and/or the Services.
    </p>
    <br />
    <p>
      5.3 In addition to any other remedies it may have, either party may also terminate this Agreement upon thirty (30)
      days’ notice (or without notice in the case of nonpayment), if the other party materially breaches any of the
      terms or conditions of this Agreement. Host Partner will pay in full for the Product and/or the Services up to and
      including the last day on which the Product and/or the Services are provided. Upon any termination, CETV will make
      all Host Partner Data available to Host Partner for electronic retrieval for a period of thirty (30) days, but
      thereafter CETV may, but is not obligated to, delete stored Host Partner Data. All sections of this Agreement
      which by their nature should survive termination will survive termination, including, without limitation, accrued
      rights to payment, confidentiality obligations, warranty disclaimers, and limitations of liability.
    </p>
    <br />

    <h3>6. WARRANTY AND DISCLAIMER</h3>
    <br />
    <p>
      CETV shall use reasonable efforts consistent with prevailing industry standards to maintain the Product and/or the
      Services in a manner which minimizes errors and interruptions in the Product and/or the Services and shall perform
      the implementation of the Product and the Services in a professional and workmanlike manner. Services may be
      temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by CETV or by
      third-party providers, or because of other causes beyond CETV’s reasonable control, but CETV shall use reasonable
      efforts to provide advance notice in writing or by e-mail of any scheduled service disruption. HOWEVER, CETV DOES
      NOT WARRANT THAT THE PRODUCT AND/OR THE SERVICES WILL BE UNINTERRUPTED OR ERROR FREE; NOR DOES IT MAKE ANY
      WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE PRODUCT AND/OR THE SERVICES; NOR DOES IT WARRANT
      ANY EQUIPMENT OR ANY PRODUCT OR SERVICE WHATSOEVER PROVIDED BY ANY THIRD PARTY INCLUDING BUT NOT LIMITED TO DTV.
      EXCEPT AS EXPRESSLY SET FORTH IN THIS SECTION, THE PRODUCT AND/OR THE SERVICES AND IMPLEMENTATION SERVICES ARE
      PROVIDED “AS IS” AND CETV DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED
      WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
    </p>
    <br />

    <h3>7. LIMITATION OF LIABILITY</h3>
    <br />
    <p>
      NOTWITHSTANDING ANYTHING TO THE CONTRARY, CETV AND ITS SUPPLIERS (INCLUDING BUT NOT LIMITED TO ALL EQUIPMENT AND
      TECHNOLOGY SUPPLIERS), OFFICERS, AFFILIATES, REPRESENTATIVES, CONTRACTORS AND EMPLOYEES SHALL NOT BE RESPONSIBLE
      OR LIABLE WITH RESPECT TO ANY SUBJECT MATTER OF THIS AGREEMENT OR TERMS AND CONDITIONS RELATED THERETO UNDER ANY
      CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER THEORY: (A) FOR ERROR OR INTERRUPTION OF USE OR FOR LOSS OR
      INACCURACY OR CORRUPTION OF DATA OR COST OF PROCUREMENT OF SUBSTITUTE GOODS, SERVICES OR TECHNOLOGY OR LOSS OF
      BUSINESS; (B) FOR ANY INDIRECT, EXEMPLARY, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES; (C) FOR HOST PARTNER’S
      CHARGE OF ANY MONTHLY SERVICE FEE (D) FOR ANY MATTER BEYOND CETV’S REASONABLE CONTROL; OR (E) FOR ANY AMOUNTS
      THAT, TOGETHER WITH AMOUNTS ASSOCIATED WITH ALL OTHER CLAIMS, EXCEED THE SERVICE FEES PAID OR DEBITED UNDER THIS
      AGREEMENT IN THE ONE (1) MONTH PRIOR TO THE ACT THAT GAVE RISE TO THE LIABILITY, IN EACH CASE, WHETHER OR NOT CETV
      HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
    </p>
    <br />

    <h3>8. INDEMNIFICATION</h3>
    <br />
    <p>
      Host Partner agrees to defend, indemnify, and hold harmless CETV, its members, officers, employees, agents,
      representatives, suppliers, and content and service providers from and against any and all losses, expenses,
      damages, and costs, including reasonable attorneys’ fees, resulting from any violation of these TOS or any
      activity related to Host Partner’s use of the Product and/or the Services or the Website or by any other person or
      entity accessing the Product and/or the Services or the Website through Host Partner’s equipment, computer
      systems, location, or access credentials or passwords or accounts.
    </p>
    <br />

    <h3>9. DEFAULT</h3>
    <br />
    <p>
      CETV may terminate all or any part of this Agreement, with or without notice to Host Partner, if: (a) Host Partner
      breaches any provision of this Agreement; (b) Host Partner breaches or defaults upon any commercial lease where
      the Services are being provided, which Host Partner will immediately notify CETV in such event; or (c) Host
      Partner (i) acknowledges in writing that it insolvent or is unable to pay, or fails to pay, its debts as they
      become due; (ii) files or has filed against it, a petition for voluntary or involuntary bankruptcy or otherwise
      becomes subject, voluntarily or involuntarily, to any proceeding under any domestic or foreign bankruptcy or
      insolvency law; (iii) makes or seeks to make a general assignment for the benefit of its creditors; or (iv)
      applies for or has appointed a receiver, trustee, custodian or similar agent appointed by order of any court of
      competent jurisdiction to take charge of or sell any material portion of its property or business.
    </p>
    <br />

    <h3>10. MISCELLANEOUS</h3>
    <br />
    <p>
      10.1
      <strong>Notices.</strong>
      Host Partner must send notices to CETV via certified mail with a copy by email to the following:
    </p>
    <br />
    <p>
      CETV Now, LLC
      <br />
      3219 E. Camelback Road, Suite 315
      <br />
      Phoenix, Arizona 85108
      <br />
      (480) 941-3703
      <br />
      <a href="mailto:info@cetvnow.com">info@cetvnow.com</a>
    </p>
    <br />
    <p>
      Host Partner agrees to receive electronic notices from CETV, which will be sent by email to the account
      administrator Host Partner specifies. Notices are deemed effective on the date the email is sent. Host Partner is
      responsible for ensuring that the account administrator email address that CETV has is accurate and current. Any
      email notice that CETV sends to that email address will be effective when sent, whether or not Host Partner
      actually receives the email.
    </p>
    <br />
    <p>
      10.2
      <strong>Assignment.</strong>
      This Agreement is not assignable, transferable or sublicensable by Host Partner except with CETV’s prior written
      consent. CETV may transfer and assign any of its rights and obligations under this Agreement without consent. Any
      attempted assignment by Host Partner other than as expressly authorized by CETV shall be void and of no force or
      effect. This Agreement shall inure to the benefit of and be binding upon the parties hereto and their permitted
      successors, assigns, heirs and legal representatives.
    </p>
    <br />
    <p>
      10.3
      <strong>Severability.</strong>
      If any provision of this Agreement is found to be unenforceable or invalid, that provision will be limited or
      eliminated to the minimum extent necessary so that this Agreement will otherwise remain in full force and effect
      and enforceable.
    </p>
    <br />
    <p>
      10.4
      <strong>Waiver.</strong>
      Failure by CETV to enforce any provision of this Agreement will not constitute a waiver.
    </p>
    <br />
    <p>
      10.5
      <strong>No Agency.</strong>
      The relationship between CETV and Host Partner is that of an independent contractor. This Agreement does not
      create an agency, partnership, or joint venture. Host Partner will not have the authority to bind CETV to any
      contract, transaction, obligation, or debt.
    </p>
    <br />
    <p>
      10.6
      <strong>Applicable Law.</strong>
      This Agreement is governed by Arizona law, without regard to its conflict of laws principles. Any action to
      enforce this Agreement must be brought in any court of competent jurisdiction in Phoenix, Arizona. This choice of
      jurisdiction and venue does not prevent either party from seeking injunctive relief in any appropriate
      jurisdiction with respect to violation of intellectual property rights or from joining the other party to an
      existing action filed by a third party. In the event CETV retains legal counsel to enforce its rights under this
      Agreement, Host Partner will pay CETV’s reasonable attorneys’ fees and costs whether or not a lawsuit is filed.
    </p>
    <br />
    <p>
      10.7
      <strong>Entire Agreement.</strong>
      This Agreement is the complete and exclusive statement of the mutual understanding of the parties and supersedes
      and cancels all previous written and oral agreements, communications and other understandings relating to the
      subject matter of this Agreement, and that all waivers and modifications must be in a writing signed by both
      parties, except as otherwise provided herein.
    </p>
    <br />
    <p>
      10.8
      <strong>Force Majeure.</strong>
      CETV will not be liable for any failure in performance due to causes beyond their reasonable control (such as
      fire, explosion, power blackout, earthquake, flood, severe storms, strike, embargo, labor disputes, acts of civil
      or military authority, war, terrorism (including cyber crime or terrorism), acts of God, acts or omissions of
      Internet traffic carriers, actions or omissions of regulatory or governmental bodies (including the passage of
      laws or regulations or other acts of government that impact the delivery of Products or impacts Host Partner’s
      ability to provide services at any Location).
    </p>
    <br />
    <p>
      10.9
      <strong>Website Security.</strong>
      Access to certain portions of CETV’s Website (for example, Host Partner’s account), may involve the use of a
      unique user name and/or password. Host Partner is solely responsible for maintaining the security of such user
      name(s) and password(s), and will promptly notify CETV if Host Partner believes such security has been
      compromised. Host Partner will not use the user name(s) or password(s) of any third party without such party’s
      express prior consent.
    </p>
    <br />
    <p>
      10.10
      <strong>Prohibited Uses.</strong>
      Host Partner may not use the Product and/or the Services or the Website to transmit any material that:
    </p>
    <br />
    <p>
      • Contains any obscene material or images of nudity, or describes, depicts or represents explicit sexual
      activities.
    </p>
    <br />
    <p>
      • Discriminates or encourages discrimination against people based on personal and protected characteristics or
      attributes including, but not limited to race, ethnicity, color, national origin, religion, age, sex, sexual
      orientation, gender identity and disability.
    </p>
    <br />
    <p>
      • Promotes Adult/Mature-rated films, television, video games or other media, adult entertainment facilities, or
      other adult services.
    </p>
    <br />
    <p>
      • Promotes or encourages the use of illegal drugs, tobacco products, tobacco-related products, and products that
      simulate smoking or are modeled on tobacco products, including but not limited to cigarettes, cigars, vape and
      smokeless tobacco.
    </p>
    <br />
    <p>• Constitutes, facilitates or promotes any other illegal products, services or activities.</p>
    <br />
    <p>• Promotes or encourages the use of firearms.</p>
    <br />
    <p>
      • Infringes on the intellectual property rights of any third party, including but not limited to trademarks or
      copyrights of another.
    </p>
    <br />
    <p>
      10.11
      <strong>Age of Consent.</strong>
      By using the Product and/or the Services and/or the Website, Host Partner represent that it and its users are at
      least the age of majority in its state of residence, or that Host Partner and its users are the age of majority in
      its state of residence and it has given CETV its consent to allow any minors within Host Partner’s direct control
      to use the Product and/or the Services and/or the Website.
    </p>
    <br />
    <p>
      10.12
      <strong>Changes to these Terms of Service.</strong>
      These TOS were posted on the date set forth above. CETV reserves the right, in its sole and exclusive discretion
      and without notice, to modify these TOS at any time. Host Partner is advised to periodically review these TOS for
      any changes. Any such changes will take effect immediately upon its posting on the Website. Host Partner shall
      have the right to terminate this Agreement with notice as set forth above within five (5) business days of any
      such change to the extent that such change affects any material right of Host Partner. Host Partner’s lack of
      timely termination following any such change constitutes Host Partner’s agreement to follow and be bound by the
      TOS, as amended.
    </p>
    <br />
    <p>
      10.13
      <strong>Changes to CETV Services and the Website.</strong>
      CETV reserves the right to make additions, deletions or modifications to the Product and/or the Services and/or
      the Website, in its sole and exclusive discretion and without notice, at any time.
    </p>
    <br />

    <app-item *ngIf="showCheckbox">
      <div class="checkbox-container">
        <app-checkbox [(ngModel)]="termsAccepted" (valueChange)="closePopUp()" [disable]="termsAccepted"></app-checkbox>
        <p>
          By checking this box I agree to these Terms & Conditions. I am confirming that I have carefully read these Terms
          of Service and I understand them.
        </p>
      </div>
    </app-item>
  </section>
</div>
