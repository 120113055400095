import { Injectable } from '@angular/core';
import { AlertInfo, ButtonAlert } from '../components/alert/alert.component';
import { LoadingInfo } from '../components/alert-loading/alert-loading.component';

@Injectable({
  providedIn: 'root',
})
export class AlertController {
  showAlert = false;
  loading = false;

  alertInfo: AlertInfo = {
    title: '',
    msg: '',
    showIcon: false,
    iconName: '',
    iconColor: '',
    loadingColor: '',
    buttons: [],
  };

  loadingInfo: LoadingInfo = {
    title: '',
    msg: '',
    spinnerColor: 'primary',
  };

  animationAlert = 'alertFadeIn';
  animationLoading = 'loadingFadeIn';

  /**
   *Method that opens the modal
   *@param alertInfo sets the modal info from outside
   */
  openAlert(alertInfo: AlertInfo) {
    this.animationAlert = 'alertFadeIn';
    this.alertInfo = alertInfo;
    this.showAlert = true;
  }

  startLoading(loadingInfo: LoadingInfo = { title: 'loading', msg: 'please wait...' }) {
    this.animationLoading = 'loadingFadeIn';
    this.loadingInfo = loadingInfo;
    this.loading = true;
  }

  changeLoadingProgress(data: { progressBarValue: number; title: string; msg: string }) {
    const { progressBarValue, title, msg } = data;
    this.loadingInfo.progressBarValue = progressBarValue;
    this.loadingInfo.title = title;
    this.loadingInfo.msg = msg;
  }

  changeProgressPercentage(percentage: number) {
    this.loadingInfo.progressPercentage = percentage;
  }

  confirmAction(config: {
    msg?: string;
    title?: string;
    confirmText?: string;
    cancelText?: string;
    confirmFunction: any;
    cancelFunction?: any;
    iconName?: string;
    iconColor?: string;
  }) {
    this.openAlert({
      title: config.title || 'Are you sure?',
      msg: config.msg || 'Deleted items can’t be recovered',
      showIcon: true,
      iconName: config.iconName || 'bx bxs-error-circle',
      iconColor: config.iconColor || 'warning',
      buttons: [
        {
          buttonColor: 'light',
          buttonText: config.confirmText || 'Confirm',
          buttonTextColor: 'dark',
          buttonIcon: 'bx bx-check',
          buttonHandler: config.confirmFunction,
        },
        {
          buttonColor: 'dark',
          buttonText: config.cancelText || 'No, Cancel',
          buttonHandler: () => {
            if (config.cancelFunction) {
              config.cancelFunction();
            }
            this.hideAlert();
          },
        },
      ],
    });
  }

  /**
   *hides the modal info
   */
  hideAlert() {
    this.animationAlert = 'alertFadeOut';
    setTimeout(() => {
      this.showAlert = false;
    }, 500);
  }

  stopLoading() {
    this.loading = false;
  }

  /**
   *Method that handles the success of a request with an alert
   */
  handleSuccess(alertData: { msg: string; title?: string; buttons?: ButtonAlert[] }) {
    this.openAlert({
      showIcon: true,
      iconColor: 'success',
      iconName: 'bx bxs-check-circle',
      title: alertData.title || 'Success',
      msg: alertData.msg,
      buttons: alertData.buttons || [
        {
          buttonColor: 'dark',
          buttonText: 'OK',
          buttonHandler: () => {
            this.hideAlert();
          },
        },
      ],
    });
  }

  /**
   *Method that handles error of a request with an alert
   */
  handleError(
    alertData: {
      msg: string;
      title?: string;
      iconName?: string;
      iconColor?: string;
      buttons?: ButtonAlert[];
    },
    error?: { error: { detail: string } }
  ) {
    if (error) {
      if (alertData.msg !== error.error.detail) {
        alertData.msg = `<p>${alertData.msg} ${error.error.detail}</p>`;
      } else {
        alertData.msg = `<p>${alertData.msg}</p>`;
      }
    }
    this.openAlert({
      showIcon: true,
      iconColor: alertData.iconColor || 'primary',
      iconName: alertData.iconName || 'bx bxs-error-circle',
      title: alertData.title || 'Error',
      msg: alertData.msg,
      buttons: alertData.buttons || [
        {
          buttonColor: 'dark',
          buttonText: 'OK',
          buttonHandler: () => {
            this.hideAlert();
          },
        },
      ],
    });
  }

  handleListMessages(listMessages: string[], listMessageTitle: string) {
    let message = '';
    if (listMessages && listMessages.length > 0) {
      listMessages = listMessages.map((listMessage) => `<li>${listMessage}</li>`);
      message += `
    <div class="text-align-start alert-margin-top-10">
      <p>
      ${listMessageTitle}
      </p>
   </div>
   <div class="text-align-start">
      <ul class="alert-ul">
         ${listMessages.join('')}
      </ul>
   </div>`;
    }

    return message;
  }
}
