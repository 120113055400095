// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_URL: 'https://telemated-api-dev.herokuapp.com/',
  //BASE_URL: 'http://localhost:8000/',
  STRIPE_PK:
    'pk_test_51KmIySF2BS3eQiUkpr89qBGCglolGnaV5id6WS9BzKocV2dzHcybIjCZx0sqDpCL0lg6UyYu7de5EzNRpLphleEZ00k0jozCZd',
  GOOGLE_API_KEY: 'AIzaSyAIDSFRWmo4yNVf9hE4Yhk4C93SeBTsqwo',
  };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
